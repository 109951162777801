import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cx } from "@linaria/core";
import { useQuery } from "react-query";
import { StyledList, StyledListItem } from "../../List/StyledList";
import Sidebar from "../../Sidebar/Sidebar";
import { cssSidebarGray } from "../../Sidebar/StyledSidebar";
import Categories from "../Categories/Categories";
import { CategoriesContainer, cssScrollable, cssScrollableIsInit, StyledCatalogPopup, StyledOverlay } from "./StyledPopup";
import { scrollDocumentDisable, scrollDocumentEnable } from "@/utils/helpers";
import { fetchCategoriesByBusinessArea } from "@/api/catalogAPI";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useWindowSize } from "@/hooks/windowSize";
import { setCategoriesByAreas } from "@/store/reducers/catalogSlice";
import { Container, Row } from "@/styles/utils/StyledGrid";
import { cssIsActive, getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { UpdateCurrentCategoryPopupIdType } from "@/types";
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader";
import { Typography } from "@/ui/Typography/Typography";
const Popup: FC = () => {
  const {
    categoriesByAreas: categoriesByBusinessArea,
    popup: {
      isShow
    }
  } = useAppSelector(({
    catalog
  }) => catalog);
  const {
    categories: categoriesInState,
    businessAreas
  } = useAppSelector(({
    categories
  }) => categories);
  const dispatch = useAppDispatch();
  const [currentBusinessAreaId, setCurrentBusinessAreaId] = useState<number>(-1);
  const [currentBusinessAreaUuid, setCurrentBusinessAreaUuid] = useState<string | null>(null);
  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(null);
  const {
    width
  } = useWindowSize();
  const isMoreLg = width === undefined || width > getBreakpointVal(breakpoints.lg);
  const categoriesAreaRef = useRef<HTMLDivElement | null>(null);
  const lastScrollPositionRef = useRef<number>(0);
  const [isInitScroll, setIsInitScroll] = useState<boolean | null>(null);
  const {
    isFetching
  } = useQuery(["categoriesByBusinessArea", currentBusinessAreaUuid], () => fetchCategoriesByBusinessArea(currentBusinessAreaUuid), {
    enabled: !!currentBusinessAreaUuid,
    onSuccess: data => {
      dispatch(setCategoriesByAreas({
        categoryByAreas: data || undefined,
        categoriesFetched: categoriesInState?.fetched || null
      }));
    }
  });
  const updateBAIdentifiers = (id: number, uuid: string | null) => {
    setCurrentBusinessAreaId(id);
    setCurrentBusinessAreaUuid(uuid || null);
  };
  const updateCurrentCategoryId: UpdateCurrentCategoryPopupIdType = useCallback(({
    id,
    parent
  }) => {
    if (isMoreLg) {
      setIsInitScroll(null);
      lastScrollPositionRef.current = 0;
      setCurrentCategoryId(null);
      return;
    }
    setIsInitScroll(false);
    setTimeout(() => {
      const updatedId = id === null || !(parent === 0) || currentCategoryId === id ? null : id;
      if (updatedId !== null) {
        lastScrollPositionRef.current = categoriesAreaRef.current?.scrollTop || 0;
      }
      setCurrentCategoryId(updatedId);
    }, 100);
  }, [currentCategoryId, isMoreLg]);
  const categories = useMemo(() => currentBusinessAreaId === -1 ? categoriesInState?.treeSorted || [] : categoriesByBusinessArea, [categoriesInState?.treeSorted, categoriesByBusinessArea, currentBusinessAreaId]);
  const onHideHandle = () => {
    lastScrollPositionRef.current = 0;
    if (categoriesAreaRef.current !== null) {
      categoriesAreaRef.current.scrollTop = 0;
    }
    setCurrentCategoryId(null);
    setIsInitScroll(null);
  };
  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isShow) {
      onHideHandle();
      scrollDocumentEnable();
    } else {
      scrollDocumentDisable();
    }
  }, [isShow]);
  useEffect(() => {
    if (categoriesAreaRef.current !== null) {
      if (currentCategoryId !== null) {
        categoriesAreaRef.current.scrollTop = 0;
      } else {
        categoriesAreaRef.current.scrollTop = lastScrollPositionRef.current;
      }
    }
    setIsInitScroll(true);
  }, [currentCategoryId]);
  return <>
      <StyledCatalogPopup className={cx(isShow && cssIsActive, isInitScroll !== null && (isInitScroll ? cx(cssScrollable, cssScrollableIsInit) : cx(cssScrollable)))} ref={popupRef}>
        <Container>
          <Row>
            <CategoriesContainer>
              {isFetching && <BaseLoader />}
              <Sidebar className={cssSidebarGray}>
                <StyledList>
                  <StyledListItem className={currentBusinessAreaId === -1 ? cssIsActive : ""}>
                    <Typography variant={"p14"} onClick={() => {
                    updateBAIdentifiers(-1, null);
                  }}>
                      Все сферы деятельности
                    </Typography>
                  </StyledListItem>
                  {businessAreas?.map(area => <StyledListItem key={area.id} className={currentBusinessAreaId === area.id ? cssIsActive : ""} onClick={() => {
                  if (area.id) {
                    updateBAIdentifiers(area.id, area.uuid || null);
                  }
                }}>
                      <Typography variant={"p14"}>{area.name}</Typography>
                    </StyledListItem>)}
                </StyledList>
              </Sidebar>
              <Categories categories={categories} variant={"popup"} updateCurrentCategoryId={updateCurrentCategoryId} currentCategoryId={currentCategoryId} ref={categoriesAreaRef} />
            </CategoriesContainer>
          </Row>
        </Container>
      </StyledCatalogPopup>
    </>;
};
Popup.displayName = "Popup";
export { Popup };